import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import BackgroundAnimation from "./BackgroundAnimation";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";


const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;
const StyledHeader = styled(Header)`
  ${tw`pt-2 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-primary-300 hover:border-primary-900 hover:text-primary-700`}
  }
  ${NavToggle}.closed {
    ${tw`text-primary-100 hover:text-primary-500`}
  }
`;

const HeroContainer = tw.div`z-50 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-8 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-700 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const Heading2 = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-primary-200 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const Heading3 = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-500 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Notification = tw.span`inline-block my-1 pl-3 py-1 text-primary-100 border-l-4 border-blue-500 font-medium text-sm`;
const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-primary-200 text-gray-100 font-bold rounded shadow transition duration-300 hocus:bg-gray-900 hocus:text-gray-100 focus:shadow-outline`;

export default () => {
  
  
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#consulting">Consulting</NavLink>
      <NavLink href="#solutions">Solutions</NavLink>
      <NavLink href="#training">Training</NavLink>
      <NavLink href="events">Free Events</NavLink>
      <NavLink href="#contact-us">Contact Us</NavLink>
    </NavLinks>,
  ];


  const handleNavLinkClick = (sectionId) => {
    if (sectionId) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  
  
  return (
    <Container>
      <HeroContainer>
      
      <StyledHeader links={navLinks} handleNavLinkClick={handleNavLinkClick} />
        <TwoColumn>
          <LeftColumn>
            <Notification>Automate Everything with Generative AI</Notification>
            <Heading>
              <span>AI Strategy and </span>
              <br />
              <Heading3>Technology</Heading3>
              <Heading2>Leadership</Heading2>
            </Heading>
            
          </LeftColumn>
          <RightColumn>          
          <BackgroundAnimation />
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
  
    </Container>
  );
};
