import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "Here are some of our frequently asked questions and answers.",
  faqs = [
    {
      question: "Do you provide custom training in areas of Generative AI only?",
      answer:
        "No, we are able to provide training courses tailored to your requirements in any area of technology. This can include cutting edge technologies which are just coming out of the concept stage."
    },
    {
      question: "What makes your consulting services stand out in the generative AI space ?",
      answer:
        "Our consulting services provide tailored solutions that align with your organization’s unique needs. We specialize in fine-tuning large language models, ensuring seamless interaction with complex information, and fostering innovation."
    },
    {
      question: "How do you assist organizations in implementing generative AI models efficiently ?",
      answer:
        "We leverage our deep expertise to guide organizations through every step of the implementation process, from initial assessment to full-scale deployment. Our approach is focused on minimizing costs and maximizing value, enabling organizations to harness the power of generative AI effectively."
    },
    {
      question: "Can your consulting services be customized to suit varying organizational needs ?",
      answer:
        "Absolutely! We understand that every organization has distinct needs and challenges. Our consulting services are highly adaptable, designed to offer bespoke solutions that address your specific requirements and objectives in the realm of generative AI."
    },
    {
      question: "How do your training services facilitate the understanding and application of generative AI ? ",
      answer:
        "Our training services are meticulously crafted to provide comprehensive insights into generative AI. We offer hands-on experiences and real-world applications, ensuring participants acquire the skills and knowledge necessary to leverage generative AI in their respective domains."
    },
    {
      question: "Who can benefit from your training services in generative AI ?",
      answer:
        "Our training services cater to a diverse audience, including but not limited to IT professionals, data scientists, business analysts, and organizational leaders. Whether you are a beginner or an experienced professional looking to enhance your skills in generative AI, our training programs are designed to accommodate varying levels of expertise.",
    },
    {
      question: "Do you provide ongoing support post-training ?",
      answer:
        "Indeed! We are committed to ensuring sustained learning and application. Post-training, participants have access to resources and support, enabling them to clarify doubts, apply learned concepts effectively, and stay updated on the latest developments in generative AI.",
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
