import React, { useState, useRef } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import training1 from '../../images/training1.jpg';
import training2 from '../../images/training2.jpg';
import training3 from '../../images/training3.jpg';
//... other imports and styles

const oneDayWorkshopBrochure = '/docs/Gen-AI-1-Day.pdf';
const threeDayWorkshopBrochure = '/docs/Gen-AI-3-Day.pdf';
const fiveDayWorkshopBrochure = '/docs/Gen-AI-Comprehensive.pdf';
const Container = tw.div`relative`;
const HeadingComponent = styled.div`
  span {
    ${tw`text-primary-500`}
  }
`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;
const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default ({ 
  contactUsFormRef, 
  cardLinkText = "Download Brochure",
  subheading = "Our Training Courses",
  headingHtmlComponent = (
    <HeadingComponent>
      In <span tw="text-primary-100">cutting-edge </span> technology
    </HeadingComponent>
  ),

}) => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const downloadBrochure = (brochureUrl, cardTitle) => {
    const link = document.createElement('a');
    link.href = brochureUrl;
    link.download = '';  // The download attribute. It can be left empty or you can set a filename.
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Send the Google Analytics event
    sendGAEvent('Training Section', 'Download Brochure', cardTitle);
  };
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };
  
  const sendGAEvent = (category, action, label = '') => {
    if (window.gtag) { // Check if gtag is defined in your app.
      window.gtag('event', action, {
        'event_category': category,
        'event_label': label
      });
    }
  };
  

  const cards = [
    {
      imageSrc: training1,
      company: "This concise yet insightful workshop is crafted for professionals, management, and leadership, offering a quick immersion into the world of Generative Pre-trained Transformers (GPT) and Large Language Models (LLMs). It aims to provide a foundational understanding of how these technologies can impact businesses and offers a glimpse into their potential applications. It is the perfect starting point for those looking to gain a swift introduction to Generative AI.",
      type: "Training Workshop",
      title: "High-Level Workshop on Generative AI",
      durationText: "1 - day",
      brochureUrl: oneDayWorkshopBrochure,
      locationText: "Dubai, UAE"
    },
    {
      imageSrc: training2,
      company: "Designed for professionals, management, and leadership, this intensive workshop offers a comprehensive exploration of Generative Pre-trained Transformers (GPT) and Large Language Models (LLMs). This workshop goes beyond basic awareness, diving deep into the technical stack, offering hands-on experiences, and facilitating ideation sessions to explore potential impactful use cases tailored to your business needs.",
      type: "Training Workshop and Ideation",
      title: "Deep Dive Workshop on Generative AI",
      durationText: "2 - 3 days",
      brochureUrl: threeDayWorkshopBrochure,
      locationText: "Dubai, UAE"
    },
    {
      imageSrc: training3,
      company: "Aimed at professionals, management, and leadership, this all-encompassing workshop provides an exhaustive exploration into Generative Pre-trained Transformers (GPT) and Large Language Models (LLMs). This meticulously designed program goes beyond foundational knowledge and technical insights, delving into advanced applications, strategic implementation, and organizational preparedness, with a focus on deriving and refining innovative use cases in a collaborative setting.",
      type: "Training Workshop, Ideation, Strategic Plan and Impact Assessment",
      title: "Comprehensive Workshop on Generative AI: Exploration, Application, and Strategy",
      durationText: "1 - 2 Weeks",
      brochureUrl: fiveDayWorkshopBrochure,
      locationText: "Dubai, UAE"
    }
    //... other cards
  ];

  return (
    <Container id="training">
      <Content>
      {subheading && <Subheading>{subheading}</Subheading>}
        <HeadingWithControl>
          <Heading>{headingHtmlComponent}</Heading>
          <Controls>
          <PrevButton onClick={() => {
                sliderRef?.slickPrev();
                sendGAEvent('Training Section', 'Click Prev Button');
              }}>
                <ChevronLeftIcon/>
          </PrevButton>

          <NextButton onClick={() => {
              sliderRef?.slickNext();
              sendGAEvent('Training Section', 'Click Next Button');
            }}>
              <ChevronRightIcon/>
          </NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  <RatingsInfo>
                    <TimeIcon />
                    <Rating>{card.durationText}</Rating>
                  </RatingsInfo>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer>
                      <TimeIcon />
                    </IconContainer>
                    <Text>{card.type}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description>{card.company}</Description>
              </TextInfo>
              <PrimaryButton onClick={() => downloadBrochure(card.brochureUrl, card.title)}>
                Download Brochure
              </PrimaryButton>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
