import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import hrEventImage from '../../images/hr_event_191023.jpg';

const PageContainer = styled.div`
  ${tw`mt-1 flex flex-col items-center justify-center min-h-screen bg-gray-lightest md:flex-row md:items-start md:justify-evenly`}
`; // Added responsiveness to have a row layout for medium and larger screens

const ContentContainer = styled.div`
  ${tw`w-full max-w-md p-6`}
`; // A container for the content on the left

const oldImageContainer = styled.div`
  ${tw`w-full max-w-md h-64 bg-center mx-auto`}
  background-image: url(${hrEventImage});
  background-size: cover;
`;

const ImageContainer = styled.div`
  ${tw`w-full h-64 bg-center mb-6 md:mb-0 md:h-96`}
  background-image: url(${hrEventImage});
  background-size: cover;
`; // Adjusted for a larger height on medium screens

const TextContainer = styled.p`
  ${tw`text-lg text-gray-700 mb-6`}
`; // A container for your text content

const FormContainer = styled.div`
  ${tw`w-full max-w-md mt-1 md:mt-0`}
`; // Removed the margin-top for medium screens

const Form = styled.form`
  ${tw`w-full p-6 bg-white shadow-md`}
`;

const Label = styled.label`
  ${tw`block text-sm font-semibold text-gray-700 mb-2`}
`;

const Input = styled.input`
  ${tw`w-full p-2 border rounded mb-4`}
`;

const Select = styled.select`
  ${tw`w-full p-2 border rounded mb-4`}
`;

const Button = styled.button`
  ${tw`w-full p-2 bg-blue-500 text-white rounded`}
`;

const EventRegistration = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    surname: '',
    email: '',
    company: '',
    question1: '',
    question2: '',
    question3: '',
    question4: '',
    question5: ''
  });

  const [isFormStarted, setFormStarted] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`https://cst.tracstel.com:3000/proxy?url=https://script.google.com/macros/s/AKfycbwepUvgFERvZlxa5mTn0QdsA0Ff6KJbp53GRZqfnbrQE1gbcZDwTw73s2Az0ZRVzKWx0Q/exec?action=questionFetch`);
        setQuestions(response.data);
      } catch (error) {
        console.error('Failed to fetch questions', error);
      }
    }

    fetchQuestions();
  }, []);
  
  const handleFieldFocus = (fieldName) => {
    if (!isFormStarted) {
      setFormStarted(true);
      window.gtag('event', 'start', {
        'event_category': 'form',
        'event_label': `Started ${fieldName}`
      });
    }
  };

  const handleFieldBlur = (fieldName) => {
    if (!isFormSubmitted) {
      window.gtag('event', 'abandon', {
        'event_category': 'form',
        'event_label': `Abandoned ${fieldName}`
      });
    }
  };

  const navigate = useNavigate(); // Now navigate is defined but not called immediately

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);


    const endpointUrl = `https://cst.tracstel.com:3000/proxy?url=https://script.google.com/macros/s/AKfycbwepUvgFERvZlxa5mTn0QdsA0Ff6KJbp53GRZqfnbrQE1gbcZDwTw73s2Az0ZRVzKWx0Q/exec?${Object.keys(formData)
      .map(key => `${key}=${encodeURIComponent(formData[key])}`)
      .join('&')}`;

    try {
      await axios.get(endpointUrl);
      window.gtag('event', 'submit', {
        'event_category': 'form',
        'event_label': 'HR Event Registration'
      });
      alert('You will receive the details of the event by email.');
      window.location.href = 'https://www.linkedin.com/in/drraza/'; // Navigating to external URL
    } catch (error) {
      console.error('Failed to send data', error);
      alert('Failed to send data. Please try again later.');
    }
  };

  // ... [The rest of the component remains the same]

  return (
    <PageContainer>
      <FormContainer>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="firstName">First Name</Label>
        <Input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} onFocus={() => handleFieldFocus('First Name')} onBlur={() => handleFieldBlur('First Name')} required />
        
        <Label htmlFor="surname">Surname</Label>
        <Input type="text" id="surname" name="surname" value={formData.surname} onChange={handleChange} onFocus={() => handleFieldFocus('Surname')} onBlur={() => handleFieldBlur('Surname')} required />
        
        <Label htmlFor="email">Email</Label>
        <Input type="email" id="email" name="email" value={formData.email} onChange={handleChange} onFocus={() => handleFieldFocus('Email')} onBlur={() => handleFieldBlur('Email')} required />
        
        <Label htmlFor="company">Company</Label>
        <Input type="text" id="company" name="company" value={formData.company} onChange={handleChange} onFocus={() => handleFieldFocus('Company')} onBlur={() => handleFieldBlur('Company')} required />
        
        {questions.map((item, index) => (
            <React.Fragment key={`question-${index}`}>
              <Label htmlFor={`question${index + 1}`}>{item.question}</Label>
              <Select id={`question${index + 1}`} name={`question${index + 1}`} value={formData[`question${index + 1}`]} onChange={handleChange} onFocus={() => handleFieldFocus(`question${index + 1}`)} onBlur={() => handleFieldBlur(`question${index + 1}`)} required>
                <option value="" disabled>Select an option</option>
                {item.options.map((option, optionIndex) => (
                  <option key={`option-${optionIndex}`} value={String.fromCharCode(97 + optionIndex)}>{option}</option>
                ))}
              </Select>
              </React.Fragment>
          ))}

        
        
        <Button type="submit">Register</Button>
      </Form>
      </FormContainer>
    </PageContainer>
  );
};

export default EventRegistration;
