import React, { useEffect, useState } from "react";
import { useSpring, animated } from 'react-spring';
import styled from "styled-components";
import { ReactComponent as Svg1 } from '../../images/Tech/Svg1.svg';
import { ReactComponent as Svg2 } from '../../images/Tech/Svg2.svg';
import { ReactComponent as Svg3 } from '../../images/Tech/Svg3.svg';

const StyledSvg1 = styled(animated(Svg1))`
  position: absolute;
`;

const StyledSvg2 = styled(animated(Svg2))`
  position: absolute;
`;

const StyledSvg3 = styled(animated(Svg3))`
  position: absolute;
`;

const getAnimationParams = (width) => {
  if(width <= 384) { // Phone Portrait
    return {
      svg1: { from: { transform: 'translateX(-150px) translateY(-50px)' }, to: { transform: 'translateX(10px) translateY(-370px)' }, size: '20vw' },
      svg2: { from: { transform: 'translateX(150px) translateY(350px)' }, to: { transform: 'translateX(230px) translateY(-370px)' }, size: '20vw' },
      svg3: { from: { transform: 'translateX(150px) translateY(350px)' }, to: { transform: 'translateX(130px) translateY(-400px)' }, size: '15vw'}
    };
  } else if(width <= 480) { // Phone Portrait
    return {
      svg1: { from: { transform: 'translateX(-150px) translateY(-50px)' }, to: { transform: 'translateX(50px) translateY(-370px)' }, size: '20vw' },
      svg2: { from: { transform: 'translateX(150px) translateY(350px)' }, to: { transform: 'translateX(280px) translateY(-370px)' }, size: '20vw' },
      svg3: { from: { transform: 'translateX(150px) translateY(350px)' }, to: { transform: 'translateX(170px) translateY(-370px)' }, size: '15vw'}
    };
  } else if(width <= 767) { // Phone Landscape
    return {
      svg1: { from: { transform: 'translateX(-450px) translateY(-100px)' }, to: { transform: 'translateX(50px) translateY(-370px)' }, size: '15vw' },
      svg2: { from: { transform: 'translateX(500px) translateY(150px)' }, to: { transform: 'translateX(400px) translateY(-370px)' }, size: '15vw' },
      svg3: { from: { transform: 'translateX(500px) translateY(450px)' }, to: { transform: 'translateX(250px) translateY(-370px)' }, size: '10vw' }
    }; 
  }   else if(width <= 1024) { // width <= 1024 (Tablet Portrait)
    return {
      svg1: { from: { transform: 'translateX(-450px) translateY(-100px)' }, to: { transform: 'translateX(-50px) translateY(-370px)' }, size: '15vw' },
      svg2: { from: { transform: 'translateX(500px) translateY(150px)' }, to: { transform: 'translateX(300px) translateY(-370px)' }, size: '15vw' },
      svg3: { from: { transform: 'translateX(500px) translateY(450px)' }, to: { transform: 'translateX(150px) translateY(-370px)' }, size: '10vw' }
    }; 
  } else if(width <= 1280) { // Tablet Landscape
    return {
      svg1: { from: { transform: 'translateX(-450px) translateY(-100px)' }, to: { transform: 'translateX(-10px) translateY(-350px)' }, size: '12vw' },
      svg2: { from: { transform: 'translateX(500px) translateY(150px)' }, to: { transform: 'translateX(400px) translateY(-350px)' }, size: '12vw' },
      svg3: { from: { transform: 'translateX(500px) translateY(450px)' }, to: { transform: 'translateX(200px) translateY(-350px)' }, size: '8vw' }
    }; 
  } else if(width <= 1440) { // Desktop and up
    return {
      svg1: { from: { transform: 'translateX(-450px) translateY(-100px)' }, to: { transform: 'translateX(-50px) translateY(-350px)' }, size: '12vw' },
      svg2: { from: { transform: 'translateX(500px) translateY(150px)' }, to: { transform: 'translateX(500px) translateY(-350px)' }, size: '12vw' },
      svg3: { from: { transform: 'translateX(500px) translateY(450px)' }, to: { transform: 'translateX(250px) translateY(-350px)' }, size: '8vw' }
    }; 
  } else if(width <= 1920) { // Desktop and up
    return {
      svg1: { from: { transform: 'translateX(-450px) translateY(-100px)' }, to: { transform: 'translateX(-50px) translateY(-350px)' }, size: '12vw' },
      svg2: { from: { transform: 'translateX(500px) translateY(150px)' }, to: { transform: 'translateX(500px) translateY(-350px)' }, size: '12vw' },
      svg3: { from: { transform: 'translateX(500px) translateY(450px)' }, to: { transform: 'translateX(250px) translateY(-350px)' }, size: '8vw' }
    }; 
  } else if(width <= 2560) { // Desktop and up
    return {
      svg1: { from: { transform: 'translateX(-450px) translateY(-100px)' }, to: { transform: 'translateX(-50px) translateY(-350px)' }, size: '8vw' },
      svg2: { from: { transform: 'translateX(500px) translateY(150px)' }, to: { transform: 'translateX(500px) translateY(-350px)' }, size: '8vw' },
      svg3: { from: { transform: 'translateX(500px) translateY(450px)' }, to: { transform: 'translateX(250px) translateY(-350px)' }, size: '6vw' }
    }; 
  } else if(width <= 3840) { // Desktop and up
    return {
      svg1: { from: { transform: 'translateX(-450px) translateY(-100px)' }, to: { transform: 'translateX(-50px) translateY(-350px)' }, size: '8vw' },
      svg2: { from: { transform: 'translateX(500px) translateY(150px)' }, to: { transform: 'translateX(500px) translateY(-350px)' }, size: '8vw' },
      svg3: { from: { transform: 'translateX(500px) translateY(450px)' }, to: { transform: 'translateX(250px) translateY(-350px)' }, size: '6vw' }
    }; 
  }
};

export default () => {
  const [animationParams, setAnimationParams] = useState(getAnimationParams(window.innerWidth));
  
  const props1 = useSpring({
    to: animationParams.svg1.to,
    from: animationParams.svg1.from,
    delay: 200,
    reset: true,
  });

  const props2 = useSpring({
    to: animationParams.svg2.to,
    from: animationParams.svg2.from,
    delay: 300,
    reset: true,
  });

  const props3 = useSpring({
    to: animationParams.svg3.to,
    from: animationParams.svg3.from,
    delay: 800,
    reset: true,
  });

  useEffect(() => {
    const handleResize = () => {
      setAnimationParams(getAnimationParams(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <StyledSvg1 style={{ ...props1, width: animationParams.svg1.size }} />
      <StyledSvg2 style={{ ...props2, width: animationParams.svg2.size }} />
      <StyledSvg3 style={{ ...props3, width: animationParams.svg3.size }} />
    </>
  );
};
