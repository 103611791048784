//BackgroundVideo.js
import React, { useEffect } from "react";
import Player from '@vimeo/player';
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import styled from "styled-components";
import tw from "twin.macro";

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }

  @media (min-width: 1024px) { 
    padding-bottom: 30% !important; // Adjust according to your design
    width: 50%; // Adjust to desired width
    margin: 0 auto; // Center the video
  }
  @media (min-width: 3840px) { 
    padding-bottom: 10% !important; // Adjust according to your design
    width: 20%; // Adjust to desired width
    margin: 0 auto; // Center the video
  }
`;


const BackgroundVideo = () => {
  const videoRef = React.useRef(null);

  useEffect(() => {
    if (!videoRef.current) return;

    let player;
    try {
      player = new Player(videoRef.current);
      
      const onPlay = () => {
        window.gtag('event', 'video_play', {
          'event_category': 'Videos',
          'event_label': 'AI Video'
        });
      };

      player.on('play', onPlay);

      return () => {
        if (player) {
          player.off('play', onPlay);
          player.destroy();
        }
      };
    } catch (error) {
      console.error("Error initializing Vimeo player:", error);
    }
  }, []);

  return (
    <StyledResponsiveVideoEmbed
    ref={videoRef}
    url="//player.vimeo.com/video/869045933?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
    background="transparent"
  />
  );
};

export default BackgroundVideo;
