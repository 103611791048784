import React, { useEffect, useState } from "react";
//import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line




//import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/Tracstel Logo template5.png";
//import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
//import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

export const xNavLink = tw.a`
  block lg:inline-block lg:my-2 text-xs my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const NavLink = tw.a`
  block lg:inline-block lg:my-2 text-xs my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-700
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;

export const xMobileNavLinks = styled.div`
  ${tw`lg:flex lg:items-center text-gray-100`}
  font-size: 10px; /* or whatever size you find appropriate */
  ${NavLinks} {
    ${tw`flex z-50 flex-col lg:flex-row lg:items-center`}
  }
`;
export const yMobileNavLinks = styled.div`
  ${tw`flex items-center text-gray-100 whitespace-nowrap`}
  font-size: 8px; /* or whatever size you find appropriate */

  
  ${NavLinks} {
    ${tw`flex-row z-50 items-center space-x-2`}
  }
`;


export const MobileNavLinks = styled.div`
  ${tw`flex z-50 cursor-pointer flex-col items-center text-gray-700 whitespace-nowrap`}
  font-size: 10px;
  display: ${props => (props.isOpen ? 'flex' : 'none')}; // Add this line
  // Add any additional styling for visible state
  
`;


export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg", onNavLinkClick }) => {
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };
  
  const defaultLogoLink = (
    <LogoLink href="/" style={{ color: "black" }}> 
      <img src={logo} alt="logo" />
      Tracstel Ltd.
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks} >
        {logoLink}
        {links}
      </DesktopNavLinks>
      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <NavToggle onClick={toggleNav}>
        <svg width="30" height="30" viewBox="0 0 100 80" fill="black">
<rect width="100" height="10"></rect>
<rect y="30" width="100" height="10"></rect>
<rect y="60" width="100" height="10"></rect>
</svg>
        </NavToggle>
        <MobileNavLinks isOpen={isNavVisible}>
        {links}
        </MobileNavLinks>
      </MobileNavLinksContainer>
    </Header>
  );

};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};