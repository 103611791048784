import React, { useState } from "react";
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as AIHCM } from "feather-icons/dist/icons/user-check.svg";
import { ReactComponent as WorkFlowIcon } from "feather-icons/dist/icons/shuffle.svg";
import Events from "components/forms/eventRegistration.js"

const Container = tw.div`relative`;
const xTwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-start`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const SubheadingForm = tw(SubheadingBase)`mt-8 text-center md:text-center`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

export default ({
    subheading = "Next Free Event",
  subheadingRegisterForm = "Register Below, Email Check, Join the Event",
  heading = (
    <>
      Unlock the <span tw="text-primary-500">Future of HR</span> with Generative AI
    </>
  ),
  description = (
    <>
     Dive into the transformative world of <span tw="text-primary-500">Human Resources </span> 
      at our free online event. Explore how <span tw="text-primary-500">Generative AI </span> 
      is redefining <b>Talent Acquisition</b> (TA), <b>Talent Management</b> (TM), 
      <b>Application Tracking Systems</b> (ATS), and <b>Candidate Application Management</b> (CAM). 
      Embrace the shift as we uncover the prowess of <span tw="text-primary-500">Automation </span> 
      in supercharging HR operations for unparalleled <span tw="text-primary-500">efficiency and ROI</span>. 
      Witness the dawn of a new era where Artificial Intelligence seamlessly melds with Human Capital Management, setting groundbreaking standards for the future of recruitment and talent nurturing.
    
    </>
  ),
  
  primaryButtonText = "Coming Soon",
  primaryButtonUrl = "#",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = false,
  textOnLeft = true,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
  // State to manage Modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Functions to open and close the modal
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: "Strategies",
      description: "Unravel the future of Talent Acquisition and Management with next-gen platforms.",
      iconContainerCss: tw`bg-teal-300 text-teal-800`
    },
    {
      Icon: MoneyIcon,
      title: "Efficiency",
      description: "Harness the power of Automation and AI, reaping unmatched operational efficiency and ROI.",
      iconContainerCss: tw`bg-red-300 text-red-800`
    },
    {
      Icon: WorkFlowIcon,
      title: "Integration",
      description: "Experience the symbiosis of Artificial Intelligence with Human Capital Management for revolutionary talent practices.",
      iconContainerCss: tw`bg-blue-300 text-blue-800`
    }
  ];

  if (!features) features = defaultFeatures;

  const VideoPlayerWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const WatchVideoButton = styled(PrimaryButton)`
  background-color: #F48C06;
  margin-top: 20px; // You can adjust this value as per your requirement
  display: block;
`;

  return (
    <Container id="Events">
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureHeadingContainer>
                    <FeatureIconContainer
                      iconFilled={iconFilled}
                      iconRoundedFull={iconRoundedFull}
                      css={feature.iconContainerCss || iconContainerCss}
                    >
                      {<feature.Icon />}
                    </FeatureIconContainer>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                  </FeatureHeadingContainer>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </Feature>
              ))}
            </Features>

            <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
            <WatchVideoButton
              onClick={openModal}
              >
              Watch Video
            </WatchVideoButton>
            {/* Modal Implementation */}
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Video Modal"
              style={{
                overlay: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                content: {
                  position: 'relative',
                  top: 'auto',
                  left: 'auto',
                  right: 'auto',
                  bottom: 'auto',
                  transform: 'translate(0, 0)',
                  width: '80%',
                  maxWidth: '960px', // or any max size you want
                },
              }}
            >
              <button onClick={closeModal}>Close</button>
              <VideoPlayerWrapper>
                <ReactPlayer
                  url="https://youtu.be/MVrRk-QmQ-s"
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </VideoPlayerWrapper>
            </Modal>

          </TextContent>
        </TextColumn>
        <ImageColumn>
        <SubheadingForm>{subheadingRegisterForm}</SubheadingForm>
          <Events />
        </ImageColumn>

      </TwoColumn>
    </Container>
  );
};
