import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "../../images/10.jpg";


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled.textarea.attrs({ as: "textarea" })`${tw`h-24 mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`}`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  topRef,
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
  description = "You can send us a request for more information,  a case study or training requirements you may have. ",
  submitButtonText = "Send",
  formMethod = "get",
  textOnLeft = true,
  formAction = "https://script.google.com/macros/s/AKfycbyTmTtlZ1g-r9N0yBMj4DzXw3t71TThlCAkJCWbrm2BU5taKH3eOu-4rziJLQr6xuFJIQ/exec",
}) => {
  //const topRef = React.useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    subject: "",
    message: "",
  });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#ffffff',
      padding: '20px',
    }
  };

  
  const handleChange = (e) => {
    setFormInteracted(true);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendGAEvent = (eventAction, eventCategory, eventLabel, value = 1) => {
    if (window.gtag) {
      window.gtag('event', eventAction, {
        'event_category': eventCategory,
        'event_label': eventLabel,
        'value': value
      });
    }
  };
  
  const [formInteracted, setFormInteracted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const endpointUrl = `https://cst.tracstel.com:3000/proxy?url=${encodeURIComponent(formAction)}&name=${encodeURIComponent(formData.name)}&email=${encodeURIComponent(formData.email)}&subject=${encodeURIComponent(formData.subject)}&message=${encodeURIComponent(formData.message)}`;

    fetch(endpointUrl)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status === 'success') {
          setFormInteracted(false);
          setModalIsOpen(true);
          setFormData({
            email: "",
            name: "",
            subject: "",
            message: "",
          });
          // Sending the event to Google Analytics
          sendGAEvent('form_submission', 'contact_us', 'Contact Form Submission');

        } else {
          alert('Error submitting form. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

      useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (formInteracted) {
                sendGAEvent('form_abandonment', 'contact_us', 'User abandoned the form');
                e.preventDefault();
                e.returnValue = "You have unsaved changes! Are you sure you want to leave?";
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
      }, [formInteracted]);

  return (
    <div >
    <Container id="contact-us">
      <TwoColumn>
        <ImageColumn>
        <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form method={formMethod} onSubmit={handleSubmit}>
              <Input type="email" name="email" placeholder="Your Email Address" value={formData.email} onChange={handleChange} />
              <Input type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} />
              <Input type="text" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} />
              <Textarea name="message" placeholder="Your Message Here" value={formData.message} onChange={handleChange} />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          window.scrollTo(0, 0);
        }}
        style={customStyles}
        contentLabel="Contact Submission"
      >
        <div css={tw`text-center p-8`}>
          <h2 css={tw`text-2xl font-bold mb-4`}>Your message has been sent successfully!</h2>
          <button css={tw`px-4 py-2 bg-blue-500 text-white rounded`} onClick={() => {
            setModalIsOpen(false);
            if(topRef.current) {
              topRef.current.scrollIntoView({ behavior: 'smooth' });
               }
          }}>
            Close
          </button>
        </div>
      </Modal>
    </Container>
    </div>
  );
};
