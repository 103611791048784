//import React from "react";
import React, { useRef, useEffect, useState } from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Events from "components/features/TwoColEventRegistration";
import Footer from "components/footers/MiniCenteredFooter.js";
import ReactPlayer from 'react-player';

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;


export default ({

  heading = (
    <>
      Attend the FREE 30 min. event on <span tw="text-primary-500">Generative AI for HR</span> 
    </>
  )

}) => {

  const topRef = useRef(null);
  const contactUsFormRef = useRef(null);

  const VideoPlayerWrapper = styled.div`
  position: relative;
  width: 50%;  /* Set the width to be half of the parent */
  height: 0;
  padding-bottom: 28.125%;  /* Maintain aspect ratio for responsive */
  margin: 0 auto;  /* Center the video player */
  overflow: hidden;

  iframe, object, embed, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 56.25%; /* Update padding-bottom for 16:9 aspect ratio */
  }
`;
  return (
    <AnimationRevealPage >

    <Hero />
    <Heading>{heading}</Heading>
    <VideoPlayerWrapper>
                <ReactPlayer
                  url="https://youtu.be/MVrRk-QmQ-s"
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </VideoPlayerWrapper>
    <Events />
    <Footer />
  </AnimationRevealPage>
  );

};
