//import React from "react";
import React, { useRef, useEffect, useState } from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import DPImageSrc from '../images/Dubai-police.webp';
import DPprofileImageSrc from '../images/DubaiPolicePNG.jpg';
import NTImageSrc from '../images/GAI3D_Lrg.png';
import BenImageSrc from '../images/Ben_profile.jpeg';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import BackgroundVideo from "components/hero/BackgroundVideo";
import MainFeature from "components/features/TwoColWithSteps";
import Portfolio from "components/cards/ThreeColSlider.js";
import Training from "components/cards/ThreeColSliderTraining.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import Events from "components/features/TwoColEventRegistration"

export default () => {
  const topRef = useRef(null);
  const contactUsFormRef = useRef(null);

  return (
    <AnimationRevealPage >
      <div ref={topRef}></div>
    <Hero />
    <BackgroundVideo />
    <MainFeature />
    <Portfolio contactUsFormRef={contactUsFormRef} />
    <Training />
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our Clients <span tw="text-primary-500">Trust Us.</span>
        </>
      }
      description="Here are what some of our amazing clients are saying about their experience of training or working with us."
      testimonials={[
        {
          imageSrc: DPImageSrc,
          profileImageSrc: DPprofileImageSrc,
          quote:
            "Your esteemed company has demonstrated necessary competencies and expertise in AI, making our Police force an exemplary institution in technological advancements. Eagerly looking forward to prolonging this exploration phase and our pivotal discussion.",
          customerName: "Col. Yousef",
          customerTitle: "Police"
        },
        {
          imageSrc: NTImageSrc,
          profileImageSrc: BenImageSrc,
          quote:
            "Masterfully engage and simplify complex concepts.",
          customerName: "B. Gardiner",
          customerTitle: "Senior Program Director"
        }
        
      ]}
      textOnLeft={true}
    />
    <Events />
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    />
    <div ref={contactUsFormRef}>
      <ContactUsForm topRef={topRef} />
    </div>
    <Footer />
  </AnimationRevealPage>
  );

};
